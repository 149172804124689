import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"75%","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(" My KPIs "),_c(VSpacer),(_vm.routeParams)?_c('span',{staticClass:"ml-0 text-body-1"},[_vm._v(" Application: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.routeParams))])]):_vm._e(),_c(VSpacer),(_vm.routeParams)?_c('span',{staticClass:"text-body-1"},[_vm._v(" Status:")]):_vm._e(),(_vm.routeParams)?_c(VChip,{staticClass:"mr-5",attrs:{"small":"","dark":"","color":_vm.getStatusColor(_vm.singleApplication.status).color}},[_vm._v(" "+_vm._s(_vm.singleApplication.status)+" ")]):_vm._e(),_c(VBtn,{staticClass:"mx-2 text-capitalize deep-orange lighten-4 deep-orange--text text--darken-4",attrs:{"elevation":"0","small":""},on:{"click":function($event){return _vm.GoBack()}}},[_vm._v(" close "),_c(VIcon,{staticClass:"mx-2"},[_vm._v("mdi-close")])],1)],1),_c(VDivider),_c(VCardTitle,[_c(VSpacer),(_vm.isEdit)?_c(VBtn,{staticClass:"ml-2 white--text",attrs:{"color":"primary"},on:{"click":_vm.addNew}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-plus")]),_vm._v("Add KPI ")],1):_vm._e()],1),_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.applicationLines,"search":_vm.search,"fixed-header":""},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c(VAutocomplete,{staticClass:"mb-n3 autogrow",attrs:{"items":_vm.employeeAppObjectives,"item-text":function (item) { return item.description; },"item-value":function (item) { return item.objectiveID; },"hide-details":false,"label":"Select KPI","disabled":!_vm.isEdit,"dense":"","single-line":""},on:{"change":function($event){return _vm.insertIndicator(item)}},model:{value:(item.objectiveCode),callback:function ($$v) {_vm.$set(item, "objectiveCode", $$v)},expression:"item.objectiveCode"}})]}},{key:"item.kpiCode",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c(VCombobox,{staticClass:"mb-n3 autogrow",attrs:{"items":item.indicators,"item-text":function (item) { return item.description; },"item-value":function (item) { return item.code; },"hide-details":false,"disabled":!_vm.isEdit,"value":item.kpiDescription,"label":"Select Indicator","dense":"","single-line":""},on:{"input":function($event){return _vm.input($event, index)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c('span',{staticClass:"subheading"},[_vm._v("hit enter to add new")])])]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return [(item === Object(item))?_c('span',{staticClass:"pr-2",attrs:{"input-value":selected}},[_vm._v(" "+_vm._s(item.description)+" ")]):_c('span',[_vm._v(_vm._s(item))])]}}],null,true)})]}},{key:"item.weight",fn:function(ref){
var item = ref.item;
return [(_vm.stage.allowOwnerEdit && _vm.isEdit)?_c('div',[_c(VTextField,{staticClass:"mb-n3",attrs:{"hide-details":false,"type":"number","label":"Add weight","dense":"","single-line":""},model:{value:(item.weight),callback:function ($$v) {_vm.$set(item, "weight", _vm._n($$v))},expression:"item.weight"}})],1):_c('div',[_vm._v(_vm._s(item.weight))])]}},{key:"item.target",fn:function(ref){
var item = ref.item;
return [(_vm.stage.allowOwnerEdit && _vm.isEdit)?_c('div',[_c(VTextField,{staticClass:"mb-n3",attrs:{"hide-details":false,"type":"number","label":"Add Target","dense":"","single-line":""},model:{value:(item.target),callback:function ($$v) {_vm.$set(item, "target", _vm._n($$v))},expression:"item.target"}})],1):_c('div',[_vm._v(" "+_vm._s(item.target)+" ")])]}},(_vm.isEdit)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[_c(VIcon,{staticClass:"mr-3",attrs:{"color":"green"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c(VIcon,{attrs:{"color":"red"},on:{"click":function($event){_vm.routeParams ? _vm.deleteItem(item) : _vm.remove(item)}}},[_vm._v(" mdi-delete ")])],1)]}}:null,(!_vm.isEdit)?{key:"body.append",fn:function(){return [_c('tr',[_c('th',{staticClass:"text-start"},[_c('h3',[_vm._v("Total")])]),_c('th',[_c('h3')]),_c('th',{staticClass:"text-start"},[_c('h3',[_vm._v(_vm._s(_vm.weightTotal))])]),_c('th',{staticClass:"text-start"},[_c('h3',[_vm._v(_vm._s(_vm.targetTotal))])])])]},proxy:true}:null],null,true)},[_c(VDivider,{attrs:{"inset":""}})],1),_c(VDivider),(!_vm.isEdit)?_c('ApprovalEntries',{attrs:{"approvalEntries":_vm.approvalEntries}}):_vm._e(),_c(VCardActions,[(_vm.singleApplication.status === 'Pending Approval' && _vm.routeParams)?_c(VBtn,{staticClass:"mr-2",attrs:{"color":"error","depressed":""},on:{"click":function($event){return _vm.CancelForApproval()}}},[_vm._v(" Cancel Approval ")]):_vm._e(),(_vm.isEdit)?_c(VBtn,{staticClass:"mr-2",attrs:{"color":"yellow","depressed":""},on:{"click":function($event){return _vm.SaveApplication({
            isSendForApproval: true,
          })}}},[_vm._v(" Save and Send For Approval ")]):_vm._e(),(_vm.isEdit)?_c(VBtn,{staticClass:"mr-2",attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.SaveApplication({
            isSendForApproval: false,
          })}}},[_vm._v(" Save ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }