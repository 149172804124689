<template>
  <v-container>
    <v-card elevation="0" tile class="mx-auto overflow-y-auto">
      <v-app-bar
        class="mt-3 justify-space-between"
        elevation="0"
        dense
        color="#ffffff"
      >
        <v-btn
          color="red darken-1"
          text
          elevation="0"
          class="pettycash__buttons mr-2"
          link
          to="list"
        >
          <v-icon class="mx-1">mdi-arrow-left-thin</v-icon>
          back
        </v-btn>
        <div class="text-h6">
          {{ caption ? caption : "Petty Cash" }} Request
        </div>
        <PettycashCardActions
          v-if="imprestActionsPosition === 'top'"
          :formData="formData"
          :imprestActionsPosition="imprestActionsPosition"
          :saveButtonCaption="saveButtonCaption"
          :displayPettyCashActions="displayPettyCashActions"
          @pettyCashForm="pettyCashForm"
          @sendForApproval="sendForApproval"
          @cancelApprovalRequest="cancelApprovalRequest"
          @deletePettyCash="deletePettyCash"
        />
      </v-app-bar>
      <v-divider></v-divider>
      <v-card-text>
        <div class="mx-3">
          <v-form ref="pettyCashForm" v-model="isValid">
            <v-row>
              <v-col cols="12" md="4" offset-md="8">
                <div v-if="$route.query.edit" class="mt-n2">
                  <span class="font-weight-medium">STATUS:</span>
                  <v-chip
                    class="mb-1 ml-1"
                    :color="getStatusColor(formData.status).color"
                    text-color="white"
                    small
                    dense
                  >
                    {{ formData.status }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
            <v-list>
              <v-list-item dense>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" :md="isMultiCurrencyEnabled ? '4' : '6'">
                        <v-text-field
                          dense
                          label="Employee Number"
                          :value="employee.number"
                          outlined
                          disabled
                          prepend-inner-icon="mdi-card-account-details"
                          :rules="[this.rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" :md="isMultiCurrencyEnabled ? '4' : '6'">
                        <v-text-field
                          dense
                          label="Payee Name"
                          :value="
                            employee.search_name.length > 0
                              ? employee.search_name
                              : `${employee.first_name} ${employee.middle_name} ${employee.last_name}`
                          "
                          outlined
                          disabled
                          placeholder="Enter Payee Name"
                          prepend-inner-icon="mdi-card-account-details"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <!--BEGIN currency picker -->
                      <v-col
                        cols="12"
                        md="4"
                        v-if="isMultiCurrencyEnabled"
                        sm="12"
                      >
                        <v-autocomplete
                          :items="currencies"
                          :item-text="
                            (item) => `${item.symbol}${item.displayName}`
                          "
                          :item-value="(item) => item.code"
                          v-model="formData.currencyCode"
                          dense
                          label="Select Currency"
                          outlined
                          :readonly="
                            $route.query.edit && formData.status !== 'Open'
                          "
                          persistent-placeholder
                        />
                      </v-col>
                      <!--END currency picker -->
                      <v-col cols="12" md="12" class="mt-n4">
                        <v-textarea
                          label="Description"
                          outlined
                          dense
                          placeholder="Enter Description"
                          :readonly="
                            $route.query.edit && formData.status !== 'Open'
                          "
                          prepend-inner-icon="mdi-message-text-outline"
                          :rules="[rules.required]"
                          v-model="formData.description"
                          rows="2"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="12" class="mt-n4">
                    <v-expansion-panels v-model="panel">
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <span class="table-font"
                            >Click to view &amp; add available dimensions</span
                          >
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <petty-cash-dimensions
                            :dimensionValues="dimensionValues"
                            :formData="formData"
                            :displayPettyCashActions="displayPettyCashActions"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <!--BEGIN attachment upload-->
                    <div class="mt-3">
                      <v-file-input
                        accept="image/jpeg,image/png,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        v-model="attachmentBlobs"
                        multiple
                        prepend-icon=""
                        append-icon="mdi-paperclip"
                        outlined
                        :disabled="
                          $route.query.edit && formData.status !== 'Open'
                        "
                        counter
                        :show-size="1000"
                        chips
                        :rules="rule.attachment"
                        :clearable="true"
                        label="Upload Attachment"
                      >
                        <template v-slot:selection="{ text, index, files }">
                          <v-chip small label v-if="index < 2" color="primary">
                            {{ text }}
                          </v-chip>
                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </div>
                    <!--END attachment upload-->

                    <!--BEGIN file attachments view-->
                    <div
                      v-if="attachmentBase64.length !== 0"
                      class="mt-n2 mb-2"
                    >
                      <v-icon small>mdi-paperclip</v-icon>
                      <span class="font-weight-regular">File Attachments</span>
                      <br />
                      <div class="d-flex flex-row">
                        <div v-for="(item, i) in attachmentBase64" :key="i">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                :key="i"
                                close
                                dark
                                class="mx-2"
                                color="green lighten-1"
                                @click="openDialog(item.attachment)"
                                @click:close="removeBase64(item.fileName)"
                                label
                                v-bind="attrs"
                                v-on="on"
                              >
                                <span class="table-font">{{
                                  item.fileName || "My Attachment"
                                }}</span>
                              </v-chip>
                            </template>
                            <span>click to open</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </div>
                    <!--END file attachments view-->
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-form>
        </div>
        <ExpenseItems
          :itemDialog="itemDialog"
          :expenseCodes="expenseCodes"
          :dimensionValues="dimensionValues"
          :formData="formData"
          class="mt-n4"
          :displayPettyCashActions="displayPettyCashActions"
          :destinations="destinations"
          :transportRates="transportRates"
          :isEdit="isEdit"
          :currencyCode="
            formData.currencyCode.length > 0 ? formData.currencyCode : undefined
          "
        />

        <ApprovalEntries
          v-if="$route.query.edit && formData.status !== 'Open' && approvalEntries.length"
          :approvalEntries="approvalEntries"
          class="mt-3"
        />
      </v-card-text>
      <v-divider v-if="imprestActionsPosition === 'bottom'" />
      <v-card-actions v-if="imprestActionsPosition === 'bottom'">
        <v-spacer />
        <PettycashCardActions
          :formData="formData"
          :imprestActionsPosition="imprestActionsPosition"
          :saveButtonCaption="saveButtonCaption"
          :displayPettyCashActions="displayPettyCashActions"
          @pettyCashForm="pettyCashForm"
          @sendForApproval="sendForApproval"
          @cancelApprovalRequest="cancelApprovalRequest"
          @deletePettyCash="deletePettyCash"
        />
      </v-card-actions>
    </v-card>
    <AttachmentPreview
      :base64="base64"
      :dialog="imgDialog"
      v-on:close-dialog="closeDialog"
    />
  </v-container>
</template>
<script>
import AttachmentPreview from "./AttachmentPreview.vue";
import PettyCashDimensions from "./PettyCashDimensions.vue";
import ApprovalEntries from "./ApprovalEntries.vue";
import ExpenseItems from "./PettyCashExpenseItemsTable.vue";
import PettyCashMethods from "../pettyCashMethods.js";
import RouterMixin from "@kinetics254/cassandra-base/mixins/RouterMixin";
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import PettycashCardActions from "./PettycashCardActions.vue";
export default {
  name: "PettyCashForm",
  components: {
    ExpenseItems,
    ApprovalEntries,
    PettyCashDimensions,
    AttachmentPreview,
    PettycashCardActions,
  },
  mixins: [RouterMixin],
  data: function () {
    return {
      isValid: false,
      itemDialog: false,
      imgDialog: false,
      base64: "",
      viewFileUpload: false,
      isEditLines: null,
      attachmentBlobs: [],
      attachmentBase64: [],
      panel: [0],
      formData: {
        description: "",
        pettyCashLinesAPI: [],
        attachments: [],
        shortcutDimension1Code: "",
        shortcutDimension2Code: "",
        shortcutDimension3Code: "",
        shortcutDimension4Code: "",
        currencyCode: "",
      },
      pettycashLine: {
        expense_code: "",
        description: "",
        amount: 100,
        transportCode: "",
        from: "",
        to: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        file: [
          (value) =>
            !value ||
            value.size < 2000000 ||
            "Each Image size should be less than 2 MB!",
        ],
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("pettycash/getDestinations");
      v.$store.dispatch("pettycash/getTransportRates");
      if (v.$route.query.edit) {
        v.$store.dispatch(
          "pettycash/getByDocumentNo",
          v.decodeRoute(v.$route.query.edit)
        );
      }
      v.$store.dispatch("imprest/getCurrencies");
      v.$store.dispatch("pettycash/getExpenseCodes");
      v.$store.dispatch("pettycash/getPettyCashBank");
      v.$store.dispatch("pettycash/getDimensionValues");
      v.$store.dispatch("pettycash/getfunds");
      v.$store.dispatch("pettycash/getdimensionSpeedKey");
    });
  },
  computed: {
    pettycashbank() {
      return this.$store.getters["pettycash/pettycashstate"]("pettycashbank");
    },
    caption() {
      const user = AuthService.user;
      return user.captions.pettycash_caption;
    },
    employee() {
      return this.$store.getters["auth/user"].profile;
    },
    pettycashedit() {
      return this.$store.getters["pettycash/pettycashstate"]("pettycashedit");
    },
    expenseCodes() {
      return this.$store.getters["pettycash/pettycashstate"]("expenseCodes");
    },
    displayPettyCashActions() {
      return !this.$route.query.edit || this.formData.status === "Open";
    },
    currencies() {
      return this.$store.getters["imprest/imprestGetters"]("currencies");
    },
    settings() {
      let settings = this.$store.getters["Admin/adminGetters"]("settings");
      return Object.keys(settings).length > 0
        ? JSON.parse(settings.cashmgt_settings)
        : null;
    },
    isMultiCurrencyEnabled() {
      return this.settings ? this.settings.multiple_currency : false;
    },
    saveButtonCaption() {
      return this.settings
        ? this.settings.cashmgt_save_caption ?? "Save"
        : "Save";
    },
    imprestActionsPosition() {
      return this.settings ? this.settings.cashmgt_actions_position : "top";
    },
    rule() {
      return {
        attachment: [
          (value) => !!value || "Attachment is required!",
          (value) =>
            !value ||
            !value.some((v) => v.size > 2000000) ||
            "Each file size should be less than 2 MB!",
          (value) => !value || !value.some((v) => v.size > 2000000) || false,
        ],
      };
    },
    dimensionValues() {
      return this.$store.getters["pettycash/pettycashstate"]("dimensions");
    },
    destinations() {
      return this.$store.getters["pettycash/pettycashstate"]("destinations");
    },
    transportRates() {
      return this.$store.getters["pettycash/pettycashstate"]("transportRates");
    },
    isEdit() {
      return this.$route.query.edit;
    },

    employees: function () {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees");
    },

    approvalEntries: function () {
      return (
        this.formData?.approvalEntry?.map((entry) => {
          entry.approver = this.employees.filter(
            (approver) => approver.user_id === entry.approverID
          )[0];

          return entry;
        }) ?? []
      );
    },
  },
  methods: {
    ...PettyCashMethods,
    getStatusColor: function (status) {
      return helpers.getColor(status);
    },
    openDialog(val) {
      this.imgDialog = true;
      this.base64 = val;
    },
    closeDialog: function (val) {
      this.itemDialog = val;
      this.imgDialog = val;
    },

    getFileBlob: async function (url, fileName) {
      return await helpers.getFileBlob(
        process.env.VUE_APP_FILE_API + url,
        fileName
      );
    },
  },
  watch: {
    pettycashedit: {
      handler: function (n, o) {
        if (this.$route.query.edit) {
          this.formData = { ...this.pettycashedit[0] };
          this.formData.pettyCashLinesAPI = this.formData.pettyCashLinesAPI.map(
            (value) => {
              const travel = this.transportRates
                .filter((item) => {
                  return value.transportCode === item.code;
                })
                .shift();
              value.from = travel ? travel.from : "";
              value.to = travel ? travel.to : "";
              value.amount = travel ? travel.rate : value.amount;
              return value;
            }
          );
          const blob = [];
          this.formData.attachments.map((el) => {
            if (typeof el["lineNo"] === "undefined") {
              this.getFileBlob(el.attachment, el.fileName).then((res) => {
                blob.push(res);
              });
            } else {
              this.getFileBlob(el.attachment, el.fileName).then((res) => {
                Promise.all([helpers.toBase64(res)]).then((nres) => {
                  nres = nres[0].split(",")[1];
                  el.attachment = nres;
                  console.log("attachment on else", el);
                  this.attachmentBase64.push(el);
                });
              });
            }
          });
          this.attachmentBlobs = blob;
        }
      },
    },
    attachmentBlobs: {
      handler(n, o) {
        if (n.length !== 0) {
          const base64File = [];
          for (const file of n) {
            Promise.all([helpers.toBase64(file)]).then((res) => {
              res = res[0].split(",")[1];
              if (!(file.name in base64File)) {
                this.attachmentBase64.push({
                  attachment: res,
                  fileName: file.name,
                });
              }
            });
          }
          // this.attachmentBase64 = base64File;
        }
      },
    },
  },
};
</script>
<style>
@import url("../styles.css");
</style>
