<template>
  <v-app id="inspire" style="background-color: #f0f3f4">
    <Side />
    <Page />
  </v-app>
</template>

<script>
import Side from "./AppSide";
import Page from "./AppPage";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
export default {
  name: "AppDashboard",
  components: { Page, Side },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("auth/company");
      v.$store.dispatch("auth/getCompanies");
      v.$store.dispatch("Admin/getSettings");
      v.$store.dispatch("Result/getReviewDocumentStages");
      v.$store.dispatch("Result/checkIfIsAcommitteeMember");
    });
  },
  computed: {
    settings() {
      return this.$store.getters["Admin/adminGetters"]("settings");
    },
    reviewDocumentStages() {
      return this.$store.getters["Result/appraisalResultGetter"](
        "reviewDocumentStages"
      );
    },
    userData() {
      return AuthService.user;
    },
    links: function () {
      const sideLinks = this.$store.getters["Dashboard/links"];
      sideLinks.forEach((l) => {
        l.hasSubs = l.subs && l.subs.length !== 0;
      });
      return [...sideLinks];
    },
  },
  watch: {
    reviewDocumentStages: {
      handler: function (val) {
      },
      deep: true,
    },
    links: {
      handler: function (val) {
      },
      deep: true,
    },
    settings: {
      handler: async function (val) {
        if (val) {
          if (val.site_colors) {
            const colors = JSON.parse(val.site_colors);
            colors.forEach((el) => {
              this.$vuetify.theme.themes.light[el.colorName] = el.color;
            });
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
/* #eaeaf6 */
@import "../styles.css";
</style>
