<template>
  <div class="px-10">
    <v-card flat class="mx-auto" width="80%">
      <v-card-title> Sign in </v-card-title>
      <v-card-text>
        <v-form ref="loginForm" v-model="isValid" @submit.prevent="login">
          <v-text-field
            v-model="formData.username"
            v-if="company.isWindowsAuth"
            outlined
            dense
            label="Enter Username"
            append-icon="person"
            :rules="[rules.username.required]"
          ></v-text-field>
          <v-text-field
            v-model="formData.email"
            v-else
            outlined
            dense
            label="Email Address"
            append-icon="email"
            :rules="[rules.email.required, rules.email.value]"
          ></v-text-field>
          <v-text-field
            v-model="formData.password"
            outlined
            dense
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            :rules="[rules.password.required, rules.password.min]"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            label="Password"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <div v-if="!company.isWindowsAuth">
            <router-link to="/auth/reset"
              ><small>Forgot password?</small></router-link
            >
          </div>
          <v-btn
            class="mt-2 text-capitalize"
            color="primary"
            block
            type="submit"
          >
            <div>Login</div>
            <v-icon class="mx-2">login</v-icon>
          </v-btn>
        </v-form>
        <div class="mt-4" v-if="!company.isWindowsAuth">
          <div>Dont have an account ?</div>
          <v-btn
            class="text-capitalize"
            link
            color="orange darken-2"
            :to="{ name: 'Credentials' }"
            outlined
            block
          >
            Activate
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Login",
  data: () => ({
    formData: {
      email: "",
      username: "",
      password: "",
      device_name: "apollo",
    },
    isValid: false,
    showPassword: false,
    rules: {
      password: {
        required: (value) => !!value || "Password is Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      email: {
        required: (value) => !!value || "Email is Required.",
        value: (value) => /.+@.+/.test(value) || "E-mail must be valid",
      },
      username: {
        required: (value) => !!value || "Username is Required.",
      },
    },
  }),
  computed: {
    ...mapState({
      loading: function (state) {
        return state.loader.loading;
      },
    }),
    company: function () {
      return this.$store.getters["auth/company"];
    },
  },
  methods: {
    ...mapActions({
      login: function (dispatch, event) {
        /** validate form */
        if (!this.isValid) {
          this.$refs.loginForm.validate();
        } else {
          dispatch(
            `auth/${this.company.isWindowsAuth ? "windowsLogin" : "login"}`,
            { ...this.formData }
          );
          event.target.reset();
        }
      },
    }),
  },
};
</script>
